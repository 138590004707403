import { atom } from "jotai";
import { DateTime } from "luxon";
import { intervalToDuration, isAfter, isBefore } from "date-fns";
import supportedTimezones from "../constants/SupportedTimezones";
import { getNowDate, getNowDateTime } from "../util/dates";
import TimezoneToCountryMap from "../constants/TimezoneToCountryMap";
import UKBankHolidays from "../data/bank-holidays/UK.json";
import supportedCountries from "../constants/SupportedCountries";

type AM_PM = "am" | "pm";
type Zone = string;
type Country = string;
type Area = string;
type PeakTimes = {
  [zone: Zone]: {
    [key in AM_PM]: { start: DateTime; end: DateTime };
  };
};
type Holiday = {
  title: string;

  /** @description YYYY-MM-DD */
  date: string;
  notes: string;
  bunting: boolean;
};
type BankHolidaysShape = {
  [country: Country]: {
    [area: Area]: {
      division: string;
      events: Holiday[];
    };
  };
};
const BankHolidays: BankHolidaysShape = {
  [supportedCountries.UK]: UKBankHolidays,
};
const peak_times: PeakTimes = {
  [supportedTimezones.EuropeLondon]: {
    am: {
      start: getNowDateTime().set({ hour: 6, minute: 0 }),
      end: getNowDateTime().set({ hour: 9, minute: 30 }),
    },
    pm: {
      start: getNowDateTime().set({ hour: 15, minute: 0 }),
      end: getNowDateTime().set({ hour: 19, minute: 0 }),
    },
  },
};

export const zoneAtom = atom<string>(supportedTimezones.EuropeLondon);
export const tickAtom = atom<number>(0);
/**
 * @description keeps track of the current day in an atomic state.
 * Storing the value as yyyy-mm-dd means any selectors of this atom are memoized
 * */
export const yyyymmddAtom = atom<string>("");

const ampmAtom = atom<AM_PM>((get) => {
  get(tickAtom);
  const zone = get(zoneAtom);

  const now = getNowDateTime(zone);

  return now.hour >= 12 ? "pm" : "am";
});

export const isWeekendAtom = atom<boolean>((get) => {
  get(tickAtom);
  const zone = get(zoneAtom);

  const now = getNowDateTime(zone);

  return [6, 7].includes(now.weekday);
});

export const isBankHolidayAtom = atom<boolean>((get) => {
  get(tickAtom);
  const zone = get(zoneAtom);

  const nowYYYYMMDD = get(yyyymmddAtom);
  const zoneMappedToCountry = TimezoneToCountryMap[zone];

  if (!zoneMappedToCountry) return false;

  const bankHolidayData =
    BankHolidays[zoneMappedToCountry]["england-and-wales"];

  if (!bankHolidayData) return false;

  return !!bankHolidayData.events.find((event) => event.date === nowYYYYMMDD);
});

export const isPeakAtom = atom<boolean>((get) => {
  get(tickAtom);
  get(isBankHolidayAtom);

  const zone = get(zoneAtom);
  const ampm = get(ampmAtom);
  const isWeekend = get(isWeekendAtom);
  const isBankHoliday = get(isBankHolidayAtom);

  if (isWeekend) return false;
  if (isBankHoliday) return false;

  const now = getNowDate(zone);
  const peak_time_data = peak_times[zone][ampm];
  return (
    isAfter(now, peak_time_data.start.toJSDate()) &&
    isBefore(now, peak_time_data.end.toJSDate())
  );
});

export const timeToAtom = atom<Duration>((get) => {
  get(tickAtom);
  const zone = get(zoneAtom);
  const ampm = get(ampmAtom);
  const peak = get(isPeakAtom);
  const isWeekend = get(isWeekendAtom);

  const now = getNowDateTime(zone);
  const nowDate = getNowDate(zone);

  let peak_time_data: PeakTimes[Zone][AM_PM] = peak_times[zone][ampm];
  switch (ampm) {
    case "am":
      if (isAfter(nowDate, peak_times[zone].am.end.toJSDate())) {
        peak_time_data = peak_times[zone].pm;
      }
      break;
    case "pm":
      if (isAfter(nowDate, peak_times[zone].pm.end.toJSDate())) {
        peak_time_data = peak_times[zone].am;
      }
      break;
  }

  let weekendDayOffset = 0;
  if (isWeekend) {
    peak_time_data = peak_times[zone]["am"]; // set peak_time_data to am as that will be next peak (Monday am)
    weekendDayOffset = 8 - now.weekday;
  }

  if (peak) {
    return intervalToDuration({
      start: nowDate,
      end: peak_time_data.end.plus({ day: weekendDayOffset }).toJSDate(),
    });
  }

  const nextPeakStart = peak_time_data.start
    .plus({ day: (ampm === "pm" ? 1 : 0) + weekendDayOffset })
    .toJSDate();

  return intervalToDuration({
    start: nowDate,
    end: nextPeakStart,
  });
});
