import * as React from "react";
import { Platform, Pressable, StyleSheet } from "react-native";
import spacing, { GUTTER } from "../constants/Spacing";
import { Container } from "./View";
import { sWidth } from "../constants/Layout";
import { DrawerActions, useNavigation } from "@react-navigation/native";

const Hamburger = () => {
  const navigation = useNavigation();

  const handleToggleDrawer = React.useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  return (
    <Pressable style={styles.hamburgerContainer} onPress={handleToggleDrawer}>
      <Container
        inverted
        style={[styles.hamburgerLayer, styles.hamburgerLayerSmall]}
      />
      <Container inverted style={styles.hamburgerLayer} />
      <Container inverted style={styles.hamburgerLayer} />
    </Pressable>
  );
};

const HeaderNav = () => {
  return (
    <Container style={styles.container}>
      <Hamburger />
    </Container>
  );
};
export default HeaderNav;

const isWeb = Platform.OS === "web";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 0,
  },
  hamburgerContainer: {
    alignItems: "flex-end",
    padding: GUTTER / 2,
    paddingRight: 0,
  },
  hamburgerLayer: {
    marginBottom: (isWeb ? 8 : spacing.space8) * 0.8,
    padding: 0,
    height: (isWeb ? 5 : spacing.space5) * 0.9,
    borderRadius: (isWeb ? 5 : spacing.space5) / 2,
    width: isWeb ? 50 : sWidth * 0.1,
  },
  hamburgerLayerSmall: {
    width: isWeb ? 35 : sWidth * 0.065,
  },
});
