import supportedTimezones from "./SupportedTimezones";
import supportedCountries from "./SupportedCountries";

type CountryCode = string;
const TimezoneToCountryMap: {
  [timezone: string]: CountryCode;
} = {
  [supportedTimezones.EuropeLondon]: supportedCountries.UK,
};

export default TimezoneToCountryMap;
