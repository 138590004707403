const ORANGE_RED = "#FF4500";
const MIDNIGHT_BLUE = "#0C0C43";
const PINE = "#FDFDB1";
const TEAL = "#008080";

export default {
  peak_background: ORANGE_RED,
  peak_text: MIDNIGHT_BLUE,

  off_peak_background: TEAL,
  off_peak_text: PINE,
} as const;
