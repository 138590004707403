import * as React from "react";
import Svg, { SvgProps, G, Mask, Path, Defs, ClipPath } from "react-native-svg";

const UnitedKingdom = (props: SvgProps) => (
  <Svg width={900} height={601} viewBox={`0 0 900 601`} fill="none" {...props}>
    <G clipPath="url(#a)">
      <Mask
        id="b"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={900}
        height={601}
      >
        <Path d="M0 .092v600h900v-600H0Z" fill="#fff" />
      </Mask>
      <G mask="url(#b)">
        <Path d="M0-.908v600h900v-600H0Z" fill="#012169" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M313.493 300.092-188 49.345l54.627-109.253L450.06 231.808l583.43-291.716 54.63 109.253-501.493 250.747 501.493 250.746-54.63 109.254-583.43-291.717-583.433 291.717L-188 550.838l501.493-250.746Z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M359.014 300.137-178.897 31.181l36.418-72.835 592.537 296.268L1042.6-41.654l36.41 72.835-537.907 268.956 537.907 268.955-36.41 72.836-592.542-296.269-592.537 296.269-36.418-72.836 537.911-268.955Z"
          fill="#C8102E"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M551.85 401.89v203.582H348.268V401.89h-508.955V198.308h508.955V-5.274H551.85v203.582h508.96V401.89H551.85Z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M511.134 361.173v244.299H388.985V361.173h-549.672V239.024h549.672V-5.274h122.149v244.298h549.676v122.149H511.134Z"
          fill="#C8102E"
        />
      </G>
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(0 .092)" d="M0 0h900v600H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default UnitedKingdom;
