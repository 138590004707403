import { ViewProps, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import WithPeakStyles, {
  Props as WithPeakStylesProps,
} from "./HOC/WithPeakStyles";
import { GUTTER } from "../constants/Spacing";
import Animated from "react-native-reanimated";
import * as React from "react";
import { sHeight, TAB_BAR_HEIGHT } from "../constants/Layout";

type BaseProps = {} & Animated.AnimateProps<ViewProps> &
  Pick<WithPeakStylesProps, "inverted">;

export function Container({
  inverted,
  scrollable,
  ...props
}: { scrollable?: boolean } & BaseProps) {
  const Component = scrollable ? Animated.ScrollView : Animated.View;
  return (
    <WithPeakStyles inverted={inverted} type={"container"}>
      {(peakStyles) => (
        <Component
          {...props}
          style={[{ padding: GUTTER }, peakStyles, props.style]}
          {...(scrollable
            ? {
                showsHorizontalScrollIndicator: false,
                showsVerticalScrollIndicator: false,
              }
            : {})}
        />
      )}
    </WithPeakStyles>
  );
}

export function BorderedContainer(props: BaseProps) {
  return (
    <WithPeakStyles type={"container__bordered"}>
      {(peakStyles) => (
        <Container
          {...props}
          style={[{ borderWidth: GUTTER / 4 }, peakStyles, props.style]}
        />
      )}
    </WithPeakStyles>
  );
}

export function Page({ children, ...props }: BaseProps) {
  return (
    <WithPeakStyles type={"container"}>
      {(peakStyles) => (
        <>
          <Container
            {...props}
            style={[{ minHeight: sHeight }, peakStyles, props.style]}
            scrollable
          >
            <>
              <SafeAreaView edges={["top"]} />
              {children}
              <SafeAreaView
                edges={["bottom"]}
                style={{ marginBottom: TAB_BAR_HEIGHT }}
              />
            </>
          </Container>
        </>
      )}
    </WithPeakStyles>
  );
}

type SpacedColumnProps = {
  debug?: boolean;
  small?: boolean;
  children: React.ReactElement[] | any;
} & Omit<ViewProps, "children">;
export function SpacedColumn({
  debug,
  small,
  children,
  ...props
}: SpacedColumnProps) {
  return (
    <View {...props}>
      {!!children &&
        children.map((child: any, i: any) => {
          return (
            <View key={child.key || i}>
              {child}
              <View
                style={[
                  { height: small ? GUTTER / 2 : GUTTER },
                  debug && { backgroundColor: "red" },
                ]}
              />
            </View>
          );
        })}
    </View>
  );
}

type SpacedRowProps = {
  debug?: boolean;
  small?: boolean;
  children: React.ReactElement[] | any;
} & Omit<ViewProps, "children">;
export function SpacedRow({
  debug,
  small,
  children,
  style,
  ...props
}: SpacedRowProps) {
  return (
    <View style={[{ flexDirection: "row" }, style]} {...props}>
      {!!children &&
        children.map((child: any, i: any) => {
          return (
            <View key={child.key || i}>
              {child}
              <View
                style={[
                  { width: small ? GUTTER / 2 : GUTTER },
                  debug && { backgroundColor: "red" },
                ]}
              />
            </View>
          );
        })}
    </View>
  );
}
