import supportedTimezones from "./SupportedTimezones";
import { SvgProps } from "react-native-svg";
import UnitedKingdom from "../components/SVG/UnitedKingdom";

const flagSVGByZone: {
  [zone in string]: {
    component: (props: SvgProps) => JSX.Element;
    label: string;
  };
} = {
  [supportedTimezones.EuropeLondon]: {
    component: UnitedKingdom,
    label: "UK",
  },
};

export default flagSVGByZone;
