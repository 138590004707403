import * as React from "react";
import {
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { useAtom } from "jotai";

import Colors from "../../constants/Colors";
import { colorChangeConfig } from "../../constants/AnimationConfig";

import { isPeakAtom } from "../../store/peak";

const backgroundColorStates = [
  Colors.peak_background,
  Colors.off_peak_background,
];
const textColorStates = [Colors.peak_text, Colors.off_peak_text];

export type Props = {
  type: "container" | "container__bordered" | "text";
  inverted?: boolean;
  children: (peakStyles: any) => any;
};

function WithPeakStyles({ type, inverted, children }: Props) {
  const [isPeak] = useAtom(isPeakAtom);

  const peak = useDerivedValue(() => {
    return withTiming(isPeak ? 1 : 0, colorChangeConfig);
  }, [isPeak]);

  const peakStyles = useAnimatedStyle(() => {
    switch (type) {
      case "container":
        return {
          backgroundColor: interpolateColor(
            peak.value,
            [1, 0],
            inverted ? textColorStates : backgroundColorStates
          ),
        };
      case "container__bordered":
        return {
          backgroundColor: interpolateColor(
            peak.value,
            [1, 0],
            inverted ? textColorStates : backgroundColorStates
          ),
          borderColor: interpolateColor(
            peak.value,
            [1, 0],
            inverted ? backgroundColorStates : textColorStates
          ),
        };
      case "text":
        return {
          color: interpolateColor(
            peak.value,
            [1, 0],
            inverted ? backgroundColorStates : textColorStates
          ),
        };
      default:
        return {};
    }
  }, [peak.value]);

  return children(peakStyles);
}

export default WithPeakStyles;
