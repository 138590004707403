import supportedTimezones from "./SupportedTimezones";

type OffPeakInfo = {
  text: string;
  referenceURL?: string;
};
const whatIsOffPeakDescriptionByTimeZone: { [zone in string]: OffPeakInfo } = {
  [supportedTimezones.EuropeLondon]: {
    text: `Generally speaking, the Off-Peak window begins after 09:30 and outside of evening commuter hours - 15:30-18:15 - for travel in and out of big cities. Super Off-Peak tickets become available after 10:00.`,
    referenceURL: `https://www.thetrainline.com/train-times/off-peak`,
  },
};

export default whatIsOffPeakDescriptionByTimeZone;
