import { Page, SpacedColumn } from "../components/View";
import { Body, H1, H2 } from "../components/Text";
import HeaderNav from "../components/HeaderNav";
import { LineDivider } from "../components/LineDivider";

const policySections: { [key: string]: string } = {
  "Information We Collect": `We collect information you provide directly to us, including information about you when you create an account, sign up for the app, and use the app. The types of information we collect may include:

Contact information, such as your name, email address, and phone number
Profile information, such as your profile picture, location, and interests
User-generated content, such as comments and posts
Information We Collect Automatically`,

  "When you access or use the app, we may also automatically collect information about you, including:": `Log information, such as your IP address, browser type, operating system, and access times
Device information, such as your device type and settings, device identifier, and battery level
Location information, such as your device location and GPS data
Usage information, such as your interactions with the app, such as the pages you visit, the features you use, and the content you view
Use of Information`,

  "We use information about you for the following purposes:": `To provide, maintain, and improve the app and its features
To respond to your support requests and assist with any technical issues
To communicate with you, including to send you updates, news, and promotional materials
To better understand your needs and interests and to personalize your experience
To enforce our terms and conditions and to comply with legal obligations
Sharing of Information`,

  "We may share information about you as follows:": `With third-party service providers who perform services on our behalf, such as hosting providers, analytics providers, and payment processors
With law enforcement or other government agencies, if required by law or if we believe it is necessary to protect our rights and the rights of others
In connection with a sale, merger, or acquisition of all or a portion of our business
With your consent or at your direction
Security of Information

We use reasonable measures to help protect information about you from unauthorized access, use, or disclosure. However, no security measures can guarantee the complete protection of your information.`,

  "Changes to This Privacy Policy": `We may update this Privacy Policy from time to time. If we make any changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our app or sending you a notification). We encourage you to review the Privacy Policy whenever you access or use the app to stay informed about our information practices and the ways you can help protect your privacy.`,

  "Contact Us": `If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at wtf.is.peak@gmail.com .`,
};

export default function PrivacyPolicyScreen() {
  return (
    <Page>
      <HeaderNav />
      <SpacedColumn>
        <H1>Privacy Policy</H1>
        <Body>
          This Privacy Policy explains the data collection, use, and disclosure
          practices of That is Peak, a mobile application. Our goal is to
          provide you with transparency and control over your personal
          information. Please read this policy carefully to understand our
          policies and practices regarding your information and how we will
          treat it.
        </Body>

        <LineDivider />

        {Object.entries(policySections).map(([heading, body]) => (
          <SpacedColumn key={heading}>
            <H2>{heading}</H2>
            <Body>{body}</Body>
          </SpacedColumn>
        ))}
      </SpacedColumn>
    </Page>
  );
}
