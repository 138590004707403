import * as React from "react";
import * as Linking from "expo-linking";
import { BorderedContainer, SpacedColumn } from "./View";
import { Body, H2, Link } from "./Text";
import { LineDivider } from "./LineDivider";
import { useAtom } from "jotai";
import { zoneAtom } from "../store/peak";
import whatIsPeakDescriptionsByZone from "../constants/WhatIsPeakDescriptionsByZone";
import { Pressable, StyleSheet } from "react-native";
import { GUTTER } from "../constants/Spacing";
import whatIsOffPeakDescriptionByTimeZone from "../constants/WhatIsOffPeakDescriptionByTimeZone";

const WhatIsPeak = () => {
  const [zone] = useAtom(zoneAtom);
  const peakInfo = whatIsPeakDescriptionsByZone[zone];
  const offPeakInfo = whatIsOffPeakDescriptionByTimeZone[zone];

  const handleViewPeakSource = React.useCallback(async () => {
    if (peakInfo.referenceURL) {
      await Linking.openURL(peakInfo.referenceURL);
    }
  }, [peakInfo.referenceURL]);

  const handleViewOffPeakSource = React.useCallback(async () => {
    if (offPeakInfo.referenceURL) {
      await Linking.openURL(offPeakInfo.referenceURL);
    }
  }, [offPeakInfo.referenceURL]);

  return (
    <SpacedColumn>
      <BorderedContainer>
        <SpacedColumn small>
          <H2>What is peak{"\n"}time?</H2>
          <LineDivider />

          <Body>{peakInfo.text}</Body>

          <Pressable
            onPress={handleViewPeakSource}
            style={styles.viewSourceContainer}
          >
            <Link>View source</Link>
          </Pressable>
        </SpacedColumn>
      </BorderedContainer>
      <BorderedContainer>
        <SpacedColumn small>
          <H2>What is off-peak?</H2>
          <LineDivider />

          <Body>{offPeakInfo.text}</Body>

          <Pressable
            onPress={handleViewOffPeakSource}
            style={styles.viewSourceContainer}
          >
            <Link>View source</Link>
          </Pressable>
        </SpacedColumn>
      </BorderedContainer>
    </SpacedColumn>
  );
};

export default WhatIsPeak;

const styles = StyleSheet.create({
  viewSourceContainer: {
    marginTop: GUTTER,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
