import { View, Image, useWindowDimensions, StyleSheet } from "react-native";
import { A } from "@expo/html-elements";
import { widths } from "./Constants";
import AppStoreLinks from "../../constants/AppStoreLinks";

type Props = {
  variant: "apple" | "android";
};
const AppStoreIcon = (props: Props) => {
  const { height, width } = useWindowDimensions();

  let _width: any = width * 0.6;
  if (width >= widths.tablet) _width = width * 0.5;
  if (width >= widths.desktop) _width = width * 0.3;

  return (
    <View>
      <Image
        source={
          props.variant === "android"
            ? require("./playstore.png")
            : require("./appstore.png")
        }
        style={{ height: height * 0.09, width: _width }}
        resizeMode={"contain"}
      />
    </View>
  );
};

export default AppStoreIcon;

export const AppStoreIconContainer = () => {
  const { width } = useWindowDimensions();

  let style: any = appStoreIconContainerStyles.mobile;
  if (width >= widths.tablet) style = appStoreIconContainerStyles.tablet;
  if (width >= widths.desktop) style = appStoreIconContainerStyles.desktop;

  return (
    <View style={[appStoreIconContainerStyles.container, style]}>
      <A
        href={AppStoreLinks.apple}
        hrefAttrs={{ target: "_blank" }}
        target="_blank"
        style={[appStoreIconContainerStyles.appStoreItem]}
      >
        <AppStoreIcon variant={"apple"} />
      </A>

      <A
        href={AppStoreLinks.android}
        hrefAttrs={{ target: "_blank" }}
        target="_blank"
        style={[appStoreIconContainerStyles.appStoreItem, { marginBottom: 0 }]}
      >
        <AppStoreIcon variant={"android"} />
      </A>
    </View>
  );
};
const appStoreIconContainerStyles = StyleSheet.create({
  container: {
    alignSelf: "center",
    justifyContent: "center",
  },
  desktop: {
    position: "absolute",
    bottom: "7.5%",
    right: 0,
    flexDirection: "column",
  },
  tablet: {
    top: "25%",
  },
  mobile: {
    flexDirection: "column",
    borderColor: "green",
    bottom: "15%",
  },

  appStoreItem: {
    marginBottom: "5%",
  },
});
