import * as React from "react";
import { TextProps } from "react-native";
import Animated from "react-native-reanimated";

import { fontWeights, fontSizes } from "../constants/Typography";

import WithPeakStyles from "./HOC/WithPeakStyles";

type BaseProps = {} & Animated.AnimateProps<TextProps>;

export function H1(props: BaseProps) {
  return (
    <WithPeakStyles type={"text"}>
      {(peakStyles) => (
        <Animated.Text
          {...props}
          style={[
            { fontSize: fontSizes.h1, fontFamily: fontWeights.bold },
            props.style,
            peakStyles,
          ]}
        />
      )}
    </WithPeakStyles>
  );
}

export function H2(props: BaseProps) {
  return (
    <WithPeakStyles type={"text"}>
      {(peakStyles) => (
        <Animated.Text
          {...props}
          style={[
            { fontSize: fontSizes.h2, fontFamily: fontWeights.bold },
            props.style,
            peakStyles,
          ]}
        />
      )}
    </WithPeakStyles>
  );
}

export function Body(props: BaseProps) {
  return (
    <WithPeakStyles type={"text"}>
      {(peakStyles) => (
        <Animated.Text
          {...props}
          style={[
            { fontSize: fontSizes.body, fontFamily: fontWeights.light },
            props.style,
            peakStyles,
          ]}
        />
      )}
    </WithPeakStyles>
  );
}

export function Link(props: BaseProps) {
  return (
    <WithPeakStyles type={"text"}>
      {(peakStyles) => (
        <Animated.Text
          {...props}
          style={[
            {
              fontSize: fontSizes.link,
              fontFamily: fontWeights.light,
              textDecorationLine: "underline",
            },
            props.style,
            peakStyles,
          ]}
        />
      )}
    </WithPeakStyles>
  );
}
