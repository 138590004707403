import supportedTimezones from "./SupportedTimezones";

type PeakInfo = {
  text: string;
  referenceURL?: string;
};
const whatIsPeakDescriptionsByZone: { [zone in string]: PeakInfo } = {
  [supportedTimezones.EuropeLondon]: {
    text: `A general rule of thumb is that Off-Peak will begin at 09:30 Mondays to Fridays in cities and large towns, and 09:00 across the rest of the network. Weekends and Bank Holidays are Off-Peak all day.`,
    referenceURL: `https://www.nationalrail.co.uk/times_fares/ticket_types/off-peak-tickets.aspx`,
  },
};

export default whatIsPeakDescriptionsByZone;
