import { ViewProps } from "react-native";
import Animated from "react-native-reanimated";
import WithPeakStyles from "./HOC/WithPeakStyles";
import { GUTTER } from "../constants/Spacing";

type BaseProps = {} & Animated.AnimateProps<ViewProps>;
export function LineDivider(props: BaseProps) {
  return (
    <WithPeakStyles inverted type={"container"}>
      {(peakStyles) => (
        <Animated.View
          {...props}
          style={[
            {
              height: GUTTER / 2,
              width: "100%",
            },
            props.style,
            peakStyles,
          ]}
        />
      )}
    </WithPeakStyles>
  );
}
