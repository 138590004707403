import * as React from "react";
import Svg, { SvgProps, Path, G } from "react-native-svg";
import Colors from "../../constants/Colors";

const SplashDesktop = (props: SvgProps) => (
  <Svg
    width={229}
    height={82}
    viewBox={[0, 0, 229, 82].join(" ")}
    fill="none"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-19.398 82.23H236.45V4.413l-88.67 7.066 4.696-4.746h-5.648l-4.914 5.214-3.125.249V6.733h-4.524v5.823l-7.079.564-2.389-6.387h-4.639l-2.709 7.163-13.16 1.05h-3.911v-4.41h10.863V6.733H95.854v8.884l-3.258.26.031-.096a7.698 7.698 0 0 0 .404-2.45c0-.845-.135-1.661-.404-2.449a5.45 5.45 0 0 0-1.239-2.103c-.557-.615-1.287-1.105-2.19-1.47-.902-.384-1.997-.576-3.285-.576h-9.278v10.416l-96.033 7.653V82.23ZM123.03 13.452l-1.244.099.62-1.747h.058l.566 1.648Zm-41.871-3.204v6.167h3.515a9.48 9.48 0 0 0 1.499-.116c.48-.076.903-.22 1.268-.432.365-.23.653-.547.864-.95.23-.404.346-.932.346-1.586 0-.653-.115-1.181-.346-1.584-.211-.404-.5-.711-.864-.922a3.33 3.33 0 0 0-1.268-.462 9.48 9.48 0 0 0-1.499-.115H81.16Z"
      fill={Colors.off_peak_background}
    />
    <Path
      d="M82.598 17.357v-6.166h3.516c.518 0 1.018.038 1.498.115.48.077.903.23 1.268.46.365.212.653.52.865.923.23.403.345.932.345 1.585 0 .653-.115 1.181-.346 1.585-.21.403-.499.72-.864.95a3.664 3.664 0 0 1-1.268.433c-.48.077-.98.115-1.498.115h-3.516Zm-4.524-9.682v20.574h4.524v-7.377h4.755c1.287 0 2.382-.182 3.285-.547.903-.384 1.633-.884 2.19-1.498a5.45 5.45 0 0 0 1.239-2.104 7.702 7.702 0 0 0 .403-2.45 7.53 7.53 0 0 0-.403-2.449 5.45 5.45 0 0 0-1.24-2.103c-.556-.615-1.286-1.105-2.19-1.47-.902-.384-1.997-.576-3.284-.576h-9.279Zm19.22 0v20.574h15.618v-3.803h-11.094v-5.043h9.97v-3.516h-9.97V11.48h10.863V7.675H97.294Zm23.872 12.621 2.68-7.55h.057l2.594 7.55h-5.331Zm.432-12.621-7.78 20.574h4.553l1.613-4.581h7.694l1.556 4.581h4.697l-7.694-20.574h-4.639Zm14.107 0v20.574h4.524v-6.627L142.823 19l6.195 9.25h5.677L145.877 15.8l8.04-8.126h-5.648l-8.04 8.53v-8.53h-4.524Z"
      fill={Colors.off_peak_text}
    />
  </Svg>
);

export default SplashDesktop;
