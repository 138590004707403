import { StyleSheet, View } from "react-native";
import { Body, H1, H2 } from "./Text";
import { useAtom } from "jotai";
import { isPeakAtom, isWeekendAtom, timeToAtom } from "../store/peak";
import { fontSizes } from "../constants/Typography";
import { GUTTER } from "../constants/Spacing";

const TimeToCountdown = () => {
  const [{ days, hours, minutes, seconds }] = useAtom(timeToAtom);
  const [peak] = useAtom(isPeakAtom);
  const [isWeekend] = useAtom(isWeekendAtom);

  // @ts-ignore
  const times: [number | undefined, string][] = [
    isWeekend ? [days, "d"] : undefined,
    [hours, "h"],
    [minutes, "m"],
    isWeekend ? undefined : [seconds, "s"],
  ].filter(Boolean);

  return (
    <View>
      <Body>{peak ? "Peak ends in" : "Peak starts in"}</Body>
      <View style={styles.timeContainer}>
        {times.map(([time, unit]) => {
          if (time == null) return null;

          return (
            <View key={unit} style={styles.timeItemContainer}>
              <H1>{time}</H1>
              <H2 style={styles.unitText}>{unit}</H2>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default TimeToCountdown;

const styles = StyleSheet.create({
  timeContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  timeItemContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginRight: GUTTER,
  },
  unitText: {
    lineHeight: fontSizes.h1 * 0.98,
  },
});
