import * as React from "react";
import Svg, { SvgProps, Path, G } from "react-native-svg";
import Colors from "../../constants/Colors";

const SplashMobile = (props: SvgProps) => (
  <Svg
    width={83}
    height={111}
    viewBox={[0, 0, 83, 111].join(" ")}
    fill="none"
    {...props}
  >
    <G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 110.784h82.368V5.632L51.084 16.096a8.61 8.61 0 0 0 .094-1.268 8.56 8.56 0 0 0-.459-2.786A6.2 6.2 0 0 0 49.31 9.65c-.633-.699-1.463-1.256-2.49-1.671-1.027-.437-2.272-.655-3.736-.655h-10.55v14.978L0 33.184v77.6Zm37.678-99.463v7.013h3.997c.59 0 1.158-.044 1.704-.131a4.164 4.164 0 0 0 1.442-.492 2.77 2.77 0 0 0 .983-1.081c.262-.459.393-1.06.393-1.802 0-.743-.13-1.344-.393-1.803a2.538 2.538 0 0 0-.983-1.048 3.79 3.79 0 0 0-1.442-.525 10.785 10.785 0 0 0-1.704-.13h-3.997Z"
        fill={Colors.off_peak_background}
      />
      <Path
        d="M38.958 19.614V12.6h3.998c.59 0 1.158.044 1.704.131a3.79 3.79 0 0 1 1.442.525c.415.24.742.59.983 1.048.262.459.393 1.06.393 1.803 0 .742-.131 1.343-.393 1.802a2.77 2.77 0 0 1-.983 1.081c-.415.24-.896.404-1.442.492-.546.087-1.114.13-1.704.13h-3.998Zm-5.144-11.01V32h5.144v-8.389h5.407c1.464 0 2.709-.207 3.736-.622 1.026-.437 1.856-1.005 2.49-1.704A6.199 6.199 0 0 0 52 18.893a8.757 8.757 0 0 0 .459-2.785c0-.962-.153-1.89-.459-2.786a6.198 6.198 0 0 0-1.41-2.392c-.633-.699-1.463-1.256-2.49-1.671-1.026-.437-2.271-.655-3.735-.655H33.814Z"
        fill={Colors.off_peak_text}
      />
    </G>
  </Svg>
);

export default SplashMobile;
