import { Page } from "../components/View";
import { H1 } from "../components/Text";
import HeaderNav from "../components/HeaderNav";

export default function NotFoundScreen() {
  return (
    <Page>
      <HeaderNav />
      <H1>This screen doesn't exist bruv.</H1>
    </Page>
  );
}
