import * as React from "react";
import Svg, { SvgProps, Path, G } from "react-native-svg";
import Colors from "../../constants/Colors";

const SplashTablet = (props: SvgProps) => (
  <Svg
    width={229}
    height={149}
    viewBox={[0, 0, 229, 149].join(" ")}
    fill="none"
    {...props}
  >
    <G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-102.132 149h421.318V7.567L171.884 20.523l9.019-9.116h-9.3l-9.395 9.967-3.844.338V11.407h-7.45v10.96l-11.126.98-4.465-11.94h-7.64l-5.087 13.451-11.067.974v-.902H95.111v-7.26H113v-6.263H87.66V27.93l-6.346.558a8.903 8.903 0 0 0 1.032-2.183 12.68 12.68 0 0 0 .665-4.033c0-1.392-.222-2.736-.665-4.033a8.975 8.975 0 0 0-2.04-3.464c-.917-1.013-2.12-1.82-3.606-2.42-1.487-.633-3.29-.95-5.41-.95H56.01v19.31l-158.142 13.91V149ZM132.919 23.95l-3.12.275 1.585-4.467h.095l1.44 4.192Zm-69.458-6.754V27.35h5.789c.854 0 1.676-.063 2.467-.19.791-.126 1.487-.363 2.088-.711a4.012 4.012 0 0 0 1.424-1.566c.38-.665.57-1.534.57-2.61 0-1.076-.19-1.945-.57-2.61-.348-.664-.823-1.17-1.424-1.518-.6-.38-1.297-.633-2.088-.76-.79-.126-1.613-.19-2.467-.19h-5.79Z"
        fill={Colors.off_peak_background}
      />
      <Path
        d="M65.83 29.063V18.91h5.79c.854 0 1.676.063 2.467.19.79.126 1.487.38 2.088.759.601.348 1.076.854 1.424 1.518.38.665.569 1.534.569 2.61 0 1.075-.19 1.945-.57 2.61a4.012 4.012 0 0 1-1.423 1.566c-.601.348-1.297.585-2.088.712-.79.126-1.613.19-2.467.19h-5.79ZM58.38 13.12V47h7.45V34.852h7.83c2.12 0 3.923-.3 5.41-.901 1.486-.633 2.688-1.455 3.606-2.468a8.975 8.975 0 0 0 2.04-3.464 12.68 12.68 0 0 0 .665-4.033c0-1.392-.222-2.736-.665-4.033a8.975 8.975 0 0 0-2.04-3.464c-.918-1.013-2.12-1.82-3.606-2.42-1.487-.633-3.29-.95-5.41-.95H58.38Zm31.65 0V47h25.719v-6.264H97.48v-8.304H113.9v-5.789H97.48v-7.26h17.89V13.12H90.03Zm39.311 20.783 4.413-12.432h.095l4.271 12.432h-8.779Zm.712-20.783L117.241 47h7.497l2.658-7.545h12.669L142.628 47h7.734l-12.669-33.88h-7.64Zm23.231 0V47h7.45V36.086l4.27-4.318L175.206 47h9.348l-14.52-20.5 13.239-13.38h-9.3l-13.239 14.045V13.12h-7.45Z"
        fill={Colors.off_peak_text}
      />
    </G>
  </Svg>
);

export default SplashTablet;
