import { useWindowDimensions, View, StyleSheet } from "react-native";
import SplashDesktop from "./SplashDesktop";
import Colors from "../../constants/Colors";
import SplashMobile from "./SplashMobile";
import { SvgProps } from "react-native-svg";
import SplashTablet from "./SplashTablet";
import { widths } from "./Constants";
import { AppStoreIconContainer } from "./AppStoreIcon";
import { FontAwesome } from "@expo/vector-icons";
import { GUTTER } from "../../constants/Spacing";

const WebLandingPage = () => {
  const { width, height } = useWindowDimensions();

  const svgProps: SvgProps = { width: "100%", height: "100%" };
  let SVG = <SplashMobile {...svgProps} width={width * 1.05} />;
  if (width >= widths.tablet) SVG = <SplashTablet {...svgProps} />;
  if (width >= widths.desktop) SVG = <SplashDesktop {...svgProps} />;

  return (
    <View
      style={[
        styles.container,
        {
          width,
          height,

          justifyContent: width < widths.tablet ? "flex-end" : "flex-start",
        },
      ]}
    >
      <View style={styles.svgContainer}>{SVG}</View>

      <View style={styles.chevronContainer}>
        <FontAwesome
          name="chevron-down"
          size={50}
          color={Colors.off_peak_text}
        />
      </View>

      <AppStoreIconContainer />
    </View>
  );
};

export default WebLandingPage;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.peak_background,
    position: "relative",
    overflow: "hidden",
  },
  svgContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  chevronContainer: {
    position: "absolute",
    bottom: GUTTER,
    left: 0,
    right: 0,
    zIndex: 999,
    alignItems: "center",
    justifyContent: "center",
  },
});
