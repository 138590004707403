import * as React from "react";
import { useAtom } from "jotai";
import { useStopwatch } from "react-timer-hook";

import { tickAtom, yyyymmddAtom, zoneAtom } from "../../store/peak";
import { getNowDateTime } from "../../util/dates";

const Clock = () => {
  const [, setTick] = useAtom(tickAtom);
  const [, setYyyymmddAtom] = useAtom(yyyymmddAtom);
  const [tz] = useAtom(zoneAtom);
  const { seconds } = useStopwatch({ autoStart: true });

  React.useEffect(() => {
    setTick(seconds);
    setYyyymmddAtom(getNowDateTime(tz).toFormat("yyyy-MM-dd"));
  }, [seconds]);

  return null;
};

export default Clock;
