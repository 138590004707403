import { H1 } from "./Text";
import { useAtom } from "jotai";
import { isBankHolidayAtom, isPeakAtom, isWeekendAtom } from "../store/peak";
import { LineDivider } from "./LineDivider";
import { View } from "react-native";

const PeakHeading = () => {
  const [peak] = useAtom(isPeakAtom);
  const [isWeekend] = useAtom(isWeekendAtom);
  const [isBankHoliday] = useAtom(isBankHolidayAtom);

  const scenarios: [boolean, string][] = [
    [isWeekend, `My G...\nIt's the Weekend`],
    [isBankHoliday, `DW...\nIt's a holiday`],
    [peak, `It's Peak\nbruv`],
    [true, `It's Off\nPeak bruv`],
  ];

  const text = scenarios.find(([show, _text]) => !!show)![1];

  return (
    <View>
      <H1>{text}</H1>
      <LineDivider />
    </View>
  );
};

export default PeakHeading;
