import * as React from "react";
import { ColorSchemeName, Pressable, StyleSheet, View } from "react-native";
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  useNavigation,
} from "@react-navigation/native";

import NotFoundScreen from "../screens/NotFoundScreen";
import HomeScreen from "../screens/HomeScreen";
import { RootStackParamList } from "../types";
import LinkingConfiguration from "./LinkingConfiguration";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { Body, H1, H2 } from "../components/Text";
import {
  BorderedContainer,
  Container,
  Page,
  SpacedColumn,
} from "../components/View";
import { LineDivider } from "../components/LineDivider";
import supportedTimezones from "../constants/SupportedTimezones";
import flagSVGByZone from "../constants/FlagSVGByZone";
import { fontSizes } from "../constants/Typography";
import { GUTTER } from "../constants/Spacing";
import PrivacyPolicyScreen from "../screens/PrivacyPolicyScreen";
import RoadMapScreen from "../screens/RoadMapScreen";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Drawer = createDrawerNavigator<RootStackParamList>();
function RootNavigator() {
  return (
    <Drawer.Navigator
      screenOptions={{ drawerPosition: "right", drawerType: "back" }}
      drawerContent={CustomDrawerContent}
    >
      <Drawer.Screen
        name="Home"
        component={HomeScreen}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="PrivacyPolicy"
        component={PrivacyPolicyScreen}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="RoadMap"
        component={RoadMapScreen}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ headerShown: false }}
      />
    </Drawer.Navigator>
  );
}

function CustomDrawerContent() {
  const navigation = useNavigation();
  return (
    <Page style={styles.container}>
      <SpacedColumn>
        <View>
          <View>
            <H1 style={{ fontSize: fontSizes.menuHeading }}>Menu</H1>
            <LineDivider />
          </View>
          <SpacedColumn style={styles.navItemList}>
            <></>
            <Pressable onPress={() => navigation.navigate("Home")}>
              <Container style={styles.navItemContainer}>
                <Body style={[styles.navItemText]}>Home</Body>
              </Container>
            </Pressable>
          </SpacedColumn>
        </View>

        <BorderedContainer>
          <SpacedColumn style={styles.zoneSelectorContainer}>
            <View>
              <H2 style={{ fontSize: fontSizes.menuSubheading }}>Zones</H2>
              <LineDivider />
            </View>

            <View>
              {Object.entries(supportedTimezones).map(([zoneKey, zone]) => {
                const FlagData = flagSVGByZone[zone];
                if (!FlagData.component) return null;

                return (
                  <FlagData.component
                    key={zoneKey}
                    width={fontSizes.menuSubheading}
                    height={fontSizes.menuSubheading}
                  />
                );
              })}
            </View>
          </SpacedColumn>
        </BorderedContainer>

        <SpacedColumn style={styles.navItemList}>
          <Pressable onPress={() => navigation.navigate("PrivacyPolicy")}>
            <Container style={styles.navItemContainer}>
              <Body style={[styles.navItemText]}>Privacy Policy</Body>
            </Container>
          </Pressable>
          <Pressable onPress={() => navigation.navigate("RoadMap")}>
            <Container style={styles.navItemContainer}>
              <Body style={[styles.navItemText]}>Road Map</Body>
            </Container>
          </Pressable>
        </SpacedColumn>
      </SpacedColumn>
    </Page>
  );
}

const styles = StyleSheet.create({
  container: { paddingTop: 0 },
  navItemContainer: {
    padding: 0,
  },
  navItemList: {
    flex: 1,
  },
  navItemText: {
    textDecorationLine: "underline",
    fontSize: fontSizes.menuBody,
  },
  zoneSelectorContainer: {},
  zoneSelectorButton: {
    flexDirection: "row",
    alignItems: "center",
    padding: GUTTER / 2,
    flex: 1,
    width: GUTTER * 2 + fontSizes.body,
  },
});
