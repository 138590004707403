import { Platform, StyleSheet } from "react-native";
import { Container, Page, SpacedColumn, SpacedRow } from "../components/View";
import TimeToCountdown from "../components/TimeToCountdown";
import PeakHeading from "../components/PeakHeading";
import HeaderNav from "../components/HeaderNav";
import WhatIsPeak from "../components/WhatIsPeak";
import WebLandingPage from "../components/WebLandingPage";

export default function HomeScreen() {
  if (Platform.OS === "web") {
    return (
      <>
        <Container style={styles.floatingBurgerContainer}>
          <HeaderNav />
        </Container>
        <WebLandingPage />
        <Container>
          <SpacedColumn>
            <PeakHeading />

            <TimeToCountdown />
            <WhatIsPeak />
          </SpacedColumn>
        </Container>
      </>
    );
  }
  return (
    <Page>
      <HeaderNav />
      <SpacedColumn>
        <PeakHeading />

        <TimeToCountdown />

        <WhatIsPeak />
      </SpacedColumn>
    </Page>
  );
}

const styles = StyleSheet.create({
  floatingBurgerContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 999,
  },
});
