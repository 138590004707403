import { Page, SpacedColumn } from "../components/View";
import { Body, H1, H2 } from "../components/Text";
import HeaderNav from "../components/HeaderNav";
import { LineDivider } from "../components/LineDivider";

const sections: { [key: string]: string } = {
  "Vote for your zone": `At the moment, the app only handles UK peak & off-peak times and with this in mind, I want to open up the app to be able to handle many different zones & countries.`,
  "Super Off-Peak": `As it stands, the app only provides information about Peak and Off-Peak times on UK Trains. I want to implement the logic to handle Super Off-Peak.`,
};

export default function RoadMapScreen() {
  return (
    <Page>
      <HeaderNav />
      <SpacedColumn>
        <H1>Road Map</H1>
        <Body>
          This page explains what is next in the pipeline for this app.
        </Body>

        <LineDivider />

        {Object.entries(sections).map(([heading, body]) => (
          <SpacedColumn key={heading}>
            <H2>{heading}</H2>
            <Body>{body}</Body>
          </SpacedColumn>
        ))}
      </SpacedColumn>
    </Page>
  );
}
